<template>
  <div>
    <button @click="showMenu = !showMenu" class="focus:outline-none">
      <i class="fas fa-cog text-xl text-white"></i>
      <!-- <i class="fa-solid fa-microphone text-white text-xl"></i> -->
    </button>
    <div
      v-if="showMenu"
      v-click-outside="hideMenu"
      class="absolute right-1 mt-4 w-72 bg-gray-700/50 text-white rounded shadow-lg py-2 z-50"
    >
      <div
        class="px-4 py-2 flex items-center justify-between border-b border-gray-300"
      >
        <span>მიკროფონის აქტივაციის ოფციები</span>
      </div>
      <div
        class="px-4 py-2 flex items-center justify-between cursor-pointer hover:bg-gray-700/80"
        @click="toggleClickRelease"
      >
        <span>დააჭირე</span>
        <span>
          <i class="fa-solid fa-circle" v-if="clickRelease"></i>
          <i class="fa-regular fa-circle" v-else></i>
        </span>
      </div>
      <div
        class="px-4 py-2 flex items-center justify-between cursor-pointer hover:bg-gray-700/80"
        @click="togglePressHold"
      >
        <span>დააჭირეთ მოჭრით</span>
        <span>
          <i class="fa-solid fa-circle" v-if="pressHold"></i>
          <i class="fa-regular fa-circle" v-else></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import VueClickOutside from "v-click-outside";
import { mapState, mapMutations } from "vuex";

export default {
  name: "SettingsMenu",
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapState(["micActiveType"]),
    clickRelease() {
      return this.micActiveType === 1;
    },
    pressHold() {
      return this.micActiveType === 2;
    },
  },
  directives: {
    "click-outside": VueClickOutside.directive,
  },
  methods: {
    ...mapMutations(["setMicActiveType"]),
    hideMenu() {
      this.showMenu = false;
    },
    toggleClickRelease() {
      this.setMicActiveType(1);
    },
    togglePressHold() {
      this.setMicActiveType(2);
    },
  },
};
</script>
