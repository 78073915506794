// const baseUrl = "https://api.cvus.app";
// const baseUrl = "http://localhost";
const baseUrl = "https://api.softwareoptimize.com";

export const meetingInterview = {
  mail: baseUrl + "/candidate/interview/mail",
  getMail: baseUrl + "/candidate/interview/get/mail/all",
  verify: baseUrl + "/candidate/interview/verify",
  converter: baseUrl + "/candidate/interview/converter",
  questionConverter: baseUrl + "/candidate/interview/question/converter",
  complateMeeting: baseUrl + "/candidate/interview/complate/meeting",
  candidateQuesiton: baseUrl + "/candidate/interview/candidate/question",
  textToSpeech: baseUrl + "/candidate/interview/text/to/question",
};

export const technicalInterviewMicrosoft = {
  confirmMail: baseUrl + "/candidate/technical/interview/microsoft/confirm/mail",
  createEvent: baseUrl + "/candidate/technical/interview/microsoft/create/event",
  suggestMeetDate: baseUrl + "/candidate/technical/interview/microsoft/suggest/meet/date",
};

export const candidate = {
  verify: baseUrl + "/candidate/personel/information/verify",
  add: baseUrl + "/candidate/personel/information/add",
  getFileList: baseUrl + "/candidate/personel/information/get/file/list",
  readFile: baseUrl + "/candidate/personel/information/read/file",
};
