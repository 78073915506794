<template>
  <div class="w-full flex justify-center items-center relative">
    <div
      class="absolute right-6 font-semibold bottom-6 text-white flex gap-4 items-center"
      v-if="$store.state.candidate.isQuestion != 1"
    >
      <div>
        {{ $store.state.candidate.questionCount }} /
        {{ $store.state.isDecision ? "3" : "5" }}
      </div>
      <button
        @click="endInterview"
        class="px-10 bg-white py-2 text-[#071238] rounded hover:bg-white/90"
      >
      დამთავრება
      </button>
    </div>

    <div
      class="min-h-screen-24 w-full flex flex-col items-center justify-center space-y-6 overflow-y-auto"
    >
      <contentPanel
        @nextStep="() => nextQuestion()"
        @resetStep="() => resetStep()"
        ref="contentPanel"
        @isDisabled="(val) => (isDisabled = val)"
        @isSounEnded="(val) => (isSoundEnded = val)"
      />
      <div class="h-24">
        <buttonItem
          ref="proccessBtn"
          :disabled="isDisabled"
          @isRecord="(val) => recordSound(val)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import contentPanel from "./components/content.vue";
import buttonItem from "@/components/recordButton.vue";
export default {
  name: "four-page",
  components: {
    contentPanel,
    buttonItem,
  },
  data() {
    return {
      isDisabled: true,
      isEndQuestion: false,
      isSoundEnded: false,
      isMobileDevice: window.innerWidth <= 768,
    };
  },
  methods: {
    recordSound(val) {
      if (val) this.$refs.contentPanel.startRecording();
      else this.$refs.contentPanel.stopRecording();
    },
    resetStep() {
      this.isDisabled = true;
      this.$refs.proccessBtn.reset();
    },
    endValue() {
      this.botSepeak = false;
      this.$nextTick(() => {
        this.disabled = false;
        this.$emit("isSounEnded", true);
        if (this.$store.state.candidate.isQuestion == 2) {
          if (!this.$store.state.candidate.isEndQuestion) {
            this.$store.commit("setQuestionCount");
          }

          let questionCount = this.$store.state.candidate.questionCount;
          if (
            !this.$store.state.candidate.isEndQuestion &&
            questionCount == (this.$store.state.isDecision ? 3 : 5)
          ) {
            this.speakText = "ბოლო კითხვა...";
            this.$store.commit("setIsEndQuestion", true);
          } else {
            this.$store.commit("setIsEndQuestion", false);
          }

          if (
            !this.$store.state.candidate.isEndQuestion &&
            questionCount == (this.$store.state.isDecision ? 4 : 6)
          ) {
            this.$store.commit("setCandidateQuestion", 3);
            this.$router.push("/complate/answer/list");
          }
        }
      });
    },
    endInterview() {
      this.$store.commit("setCandidateQuestion", 3);
      this.$router.push("/complate/answer/list");
    },
  },
  created() {
    if (this.$store.state.candidate.isQuestion == 3) {
      this.$router.push("/complate/answer/list");
    }

    if (this.isMobile() && this.$store.state.mobileState.question) {
      this.$swal({
        title: "გაგრძელება?",
        text: "გსურთ გაგრძელება?",
        icon: "question",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "გაგრძელება",
      });
    } else {
      this.$store.commit("mobileQuestion", true);
    }

    if (!this.$store.state.consentStatement.isValid && this.$route.path != "/") {
      this.$router.push("/");
    }
  },
  watch: {
    "$store.state.consentStatement.isValid"(val) {
      if (!val && this.$route.path != "/") {
        this.$router.push("/");
      }
    },
    "$store.state.candidate.isQuestion": function () {
      if (this.$store.state.candidate.isQuestion == 2) {
        let questionCount = this.$store.state.candidate.questionCount;
        if (questionCount == 0) {
          this.speakText = "გთხოვთ, საკმარისი კითხვა გამოგვეცანით...";
        }
      }
    },
  },
};
</script>
