<template>
  <div>
    <div
      class="min-h-screen-24 w-full flex flex-col items-center justify-center space-y-6 overflow-y-auto"
    >
      <textToItem
        :value="speakText"
        ref="textItem"
        @startValue="startValue"
        @endValue="endValue"
        @setLoading="(val) => (speechReady = val)"
      />
      <div class="w-full flex flex-col space-y-6">
        <div class="w-full rounded-full flex items-center justify-center">
          <!-- STEP 1 SON KISISIMDAN ÖNCESİ  -->
          <asistan :gifActive="gifActive" />
        </div>
        <!-- <speechAnimation :botSepeak="botSepeak" :speechReady="speechReady" /> -->
        <p
          class="w-8/12 mx-auto text-gray-300 text-sm max-md:text-xs max-sm:w-10/12 text-center"
        >
        თქვენ პასუხების გადახედვისთვის და საჭირო შემთხვევაში შესწორებისთვის გთხოვთ გადახედოთ დახმარების გვერდზე.
        </p>
      </div>
      <div class="h-28 w-28 max-lg:h-24 max-lg:w-24" />
    </div>
  </div>
</template>

<script>
// import speechAnimation from "../components/speechAnimation.vue";
import textToItem from "../components/textToItem";
import asistan from "@/components/asistans.vue";

export default {
  name: "go-list-message",
  components: {
    // speechAnimation,
    textToItem,
    asistan,
  },
  data() {
    return {
      gifActive: false,
      speakText: {
        url: require("@/assets/sound/replyReview.mp3"),
      },
      loadingState: true,
      botSepeak: false,
      speechReady: false,
    };
  },
  methods: {
    startValue() {
      this.gifActive = true;
      this.botSepeak = true;
    },
    endValue() {
      this.gifActive = false;
      setTimeout(() => {
        this.$router.push("/answer/list");
      }, 200);
      this.botSepeak = false;
    },
  },
  created() {
    if (!this.$store.state.consentStatement.isValid && this.$route.path !== "/") {
      this.$router.push("/");
    }
  },
  watch: {
    "$store.state.consentStatement.isValid"(val) {
      if (!val && this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>
