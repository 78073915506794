<template>
  <div class="w-full flex flex-col space-y-6">
    <!-- Text To Speak -->

    <!-- Speak To Text -->
    <soundQuestion
      v-if="!isCandidate"
      ref="soundQuestion"
      @isError="(val) => errorValue(val)"
      @expectedAnswerState="(val) => changeCandidate(val)"
      @load="(val) => (load = val)"
      :questionState="1"
      :questionType="4"
      :expectedAnswer="1"
    />

    <candidateAnswer
      v-if="isCandidate"
      ref="soundQuestion"
      @changeAnswer="(val) => changeSpeakText(val)"
      @noSoundDetected="() => noSoundQuestion()"
      @load="(val) => (load = val)"
    />
    <!-- Speak To Text -->

    <!-- Header Content -->
    <div class="w-full rounded-full flex items-center justify-center">
      <!-- SORU SORMAYA BAŞLADIĞI KISISM  -->
      <asistan :gifActive="gifActive" />
    </div>
    <!-- <speechAnimation :botSepeak="botSepeak" :speechReady="speechReady" /> -->

    <!-- Text To Speak -->
    <textToItem
      :value="getText"
      ref="textItem"
      @startValue="startValue"
      @endValue="endValue"
      @setLoading="(val) => (speechReady = val)"
    />

    <div class="w-full flex justify-center items-center">
      <p
        class="w-8/12 mx-auto text-gray-300 text-sm max-md:text-xs max-sm:w-10/12 text-center"
      >
        {{ getText.name ? formatText(getText).name : formatText(getText) }}
      </p>
    </div>

    <!-- Header Content -->
  </div>
</template>

<script>
// import speechAnimation from "../../components/speechAnimation.vue";
import textToItem from "../../components/textToItem.vue";
import candidateAnswer from "./candidateAnswer.vue";
import soundQuestion from "../../components/soundQuestion.vue";
import asistan from "@/components/asistans.vue";

export default {
  name: "content-panel",
  props: [""],
  components: {
    // speechAnimation,
    textToItem,
    candidateAnswer,
    soundQuestion,
    asistan,
  },
  data() {
    return {
      gifActive: false,
      speakText: "",
      isCandidate: false,
      erorState: false,
      botSepeak: false,
      load: false,
      isMobileDevice: window.innerWidth <= 768,
      isSoundPlaying: false,
      speechReady: false,
    };
  },
  methods: {
    resetButton() {
      this.$emit("resetStep", true);
    },
    startValue() {
      this.gifActive = true;
      this.imgtActive = false;
      this.botSepeak = true;
      this.$emit("isDisabled", true);
    },
    errorValue(val) {
      this.erorState = val;
      if (val) {
        this.synthesizeSpeech();
        this.resetButton();
      }
    },
    // errorValue(val) {
    //   if (val) {
    //     this.erorState = val
    //     this.speakText = {
    //       name: "Yanıtınız biraz belirsiz görünüyor. Soruya net bir şekilde 'evet' ya da 'hayır' şeklinde cevap verebilir misiniz?",
    //       url: require("@/assets/sound/yesNoError.mp3"),
    //     };
    //     this.synthesizeSpeech();
    //     this.resetButton();
    //   }
    // },
    endValue() {
      this.gifActive = false;
      this.imgtActive = true;
      this.botSepeak = false;
      this.$emit("isDisabled", false);

      setTimeout(() => {
        this.checkComplatedCandidate();
      }, 100);
    },
    startRecording() {
      this.$refs.soundQuestion.startRecording();
    },
    stopRecording() {
      this.$refs.soundQuestion.stopRecording();
    },

    synthesizeSpeech() {
      setTimeout(() => {
        this.$refs.textItem.synthesizeSpeech();
      }, 50);
    },
    changeSpeakText(val) {
      if (val) {
        this.speakText = val;
        this.synthesizeSpeech();
        this.resetButton();
        this.$store.commit("setQuestionCount");
      }
    },
    noSoundQuestion() {
      this.speakText = {
        name: "მე გისმენთ",
        url: require("@/assets/sound/listeningYou.mp3"),
      };
      //"Sizi dinliyorum.";
      this.synthesizeSpeech();
      this.resetButton();
    },
    changeCandidate(val) {
      if (val.expectedAnswerState == false) {
       this.$router.push("/complate/answer/list");
       this.$store.commit("setCandidateQuestion", 3);
      } else {
        this.isCandidate = true;
        this.speakText = { name: "მე გისმენთ", url: require("@/assets/sound/listeningYou.mp3"), };
        this.$store.commit("setCandidateQuestion", 2);
        this.resetButton();
        this.synthesizeSpeech();
      }
    },
    checkComplatedCandidate() {
      let questionCount = this.$store.state.candidate.questionCount;
      if (
        !this.$store.state.candidate.isEndQuestion &&
        questionCount == (this.$store.state.isDecision ? 3 : 5)
      ) {
        this.$store.commit("setCandidateQuestion", 3);
        this.$router.push("/complate/answer/list");
      }
    },

    // handlePlayButtonClicked() {
    //   this.isSoundPlaying = true;
    //   setTimeout(() => {
    //     this.$refs.textItem.playSound();
    //   }, 800);
    // },
  },
  created() {
    this.isCandidate = this.$store.state.candidate.isQuestion == 2;
    this.checkComplatedCandidate();
  },
  computed: {
    getText() {
      if (!this.erorState) {
        if (this.$store.state.candidate.isQuestion == 1) {
          return (
            this.$store.state?.infoStartMesage[1]?.text
          );
        } else if (this.speakText) {
          return this.speakText;
        } else {
          return {
            name: "მე გისმენთ",
            url: require("@/assets/sound/listeningYou.mp3"),
          };
        }
      } else {
        return {
          name: this.$store.state?.infoStartMesage[1]?.text,
          url: require("@/assets/sound/yesNoError.mp3"),
        };
      }
    },
  },
  watch: {
    load(val) {
      this.$emit("isLoad", val);
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
