import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import commonMixins from "@/plugins/commonMixins";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    micActiveType: 1,
    user: "",
    company: "",
    position: "",
    interviewDone: false,
    dateSelected: false,
    pageReloaded: false,
    questionsAndAnswer: [],
    isPreliminaryInterview: false,
    token: "",
    answerPage: 0,
    isAnswer: 1,
    interviewDates: [],
    infoStartMesage: {},

    consentStatement: {
      url: "",
      isValid: false,
    },
    mobileState: {
      question: false,
      inform: false,
    },
    candidate: {
      questionCount: 0,
      isQuestion: 1,
      isEndQuestion: false,
    },

    isDecision: false,
    decisionQuestion: 0,

    // Assessment
    activeExamName: "Listening",
    activeStep: 1,
    activeQuestions: [],
    activeQuestion: {},
    activeQuestionIndex: 0,
    dark: false,
    remainingTime: "00:00",
    examCompleted: false,
    timerId: null,
    isTimerActive: false,

    steadySound:{
      stringError: "",
      numberError: "",
      errorMessage: "",
      pleaseAskQuestions: ""
    }
  },
  getters: {
    dark: (state) => state.dark,
  },
  mutations: {
    interviewQuestions(state, val) {
      state.questionsAndAnswer = val.questions;
      state.user = val.user;
      state.company = val.company;
      state.position = val.position;
      state.token = val.token;
      state.consentStatement.url = val.consentStatement;
      state.isPreliminaryInterview =
        val.isPreliminaryInterview !== undefined && val.token !== undefined
          ? val.isPreliminaryInterview
          : false;
      state.infoStartMesage = val.infoStartMesage;
    },
    setPreInterviewAnswer(state, val) {
      state.questionsAndAnswer[state.answerPage] = {
        ...state.questionsAndAnswer[state.answerPage],
        ...val,
      };
    },
    setPageReloaded(state, val) {
      state.pageReloaded = val;
    },
    chanceIsValid(state, val) {
      state.consentStatement.isValid = val;
    },
    interviewAnswer(state, val) {
      state.questionsAndAnswer[val.questionId].answer = val.answer
        ? val.answer
        : "Algılanmadı";
      state.questionsAndAnswer[val.questionId].expectedAnswer =
        val.expectedAnswer;

      if (!val.state) {
        state.answerPage += 1;
      } else {
        state.isAnswer = 2;
      }
    },
    clearInterview(state) {
      state.questionsAndAnswer = [];
      state.user = "";
      state.company = "";
      state.token = "";
      (state.isPreliminaryInterview = false),
        (state.answerPage = 0),
        (state.isAnswer = 1);
    },
    mobileQuestion(state, val) {
      state.mobileState.question = val;
    },
    mobileInform(state, val) {
      state.mobileState.inform = val;
    },
    setCandidateQuestion(state, val) {
      state.candidate.isQuestion = val;
    },
    setQuestionCount(state) {
      state.candidate.questionCount += 1;
    },
    setIsEndQuestion(state, val) {
      state.candidate.isEndQuestion = val;
    },
    setIsDecision(state) {
      state.isDecision = true;
      state.decisionQuestion = state.answerPage + 2;
    },

    setInterviewDates(state, val) {
      state.token = val.token;
      state.consentStatement.url = val.consentStatement;
      state.interviewDates = val.interviewDates;
      state.isPreliminaryInterview =
        val.isPreliminaryInterview !== undefined && val.token !== undefined
          ? val.isPreliminaryInterview
          : false;
    },

    // Assessment
    setActiveExamName(state, name) {
      state.activeExamName = name;
    },
    setActiveStep(state, step) {
      state.activeStep = step;
    },
    setActiveQuestions(state, questions) {
      state.activeQuestions = questions;
    },
    setActiveQuestionIndex(state, index) {
      state.activeQuestionIndex = index;
    },
    toggleDarkMode(state) {
      state.dark = !state.dark;
    },
    setRemainingTime(state, time) {
      state.remainingTime = time;
    },
    setExamCompleted(state, completed) {
      state.examCompleted = completed;
    },
    setActiveQuestion(state, question) {
      state.activeQuestion = question;
    },
    setTimerId(state, timerId) {
      state.timerId = timerId;
    },
    setIsTimerActive(state, isTimerActive) {
      state.isTimerActive = isTimerActive;
    },
    setInterviewDone(state, val) {
      state.interviewDone = val;
    },
    setDateSelected(state, val) {
      state.dateSelected = val;
    },
    setMicActiveType(state, val) {
      state.micActiveType = val;
    },
  },
  actions: {
    startTimer({ commit }, duration) {
      let timer = duration;

      const timerId = setInterval(() => {
        commit("setRemainingTime", commonMixins.methods.formatTime(timer));

        if (--timer < 0) {
          timer = duration;
        }
      }, 1000);

      commit("setTimerId", timerId);
      commit("setIsTimerActive", true);
    },
    stopTimer({ commit, state }) {
      clearInterval(state.timerId);
      commit("setTimerId", null);
      commit("setIsTimerActive", false);
    },
    setTimerLength({ commit }, length) {
      let timer = length * 60;
      commit("setRemainingTime", commonMixins.methods.formatTime(timer));
    },
  },
  modules: {},
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});
