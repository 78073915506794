<template>
  <div class="w-full flex justify-center items-center min-h-screen-24 relative">
    <div class="w-full flex flex-col justify-center items-center space-y-6">
      <micTypeModal ref="micTypeModal" @result="handleMicTypeResult" />
      <button
        data-v-step="0"
        @click="start"
        :disabled="selectedMicType === null"
        class="h-44 w-44 animation-pulse bg-white circle pulse textcol text-xl flex items-center justify-center mx-auto"
      >
      დაიწყე
      </button>
      <p
        class="mt-10 w-8/12 mx-auto text-gray-300 text-base max-sm:w-10/12 text-center"
      >
        <span class="text-lg font-semibold underline">ყურადღება:</span>
        პრელიმინარული შედეგების მისაღებად, გთხოვთ დარწმუნდეთ, რომ თქვენი მოწყობილობის ხმა გახსნილია.
      </p>
      <div class="flex items-center justify-center pt-5 text-xs gap-3">
        <input
          v-model="$store.state.consentStatement.isValid"
          type="checkbox"
          class="w-5 h-5 cursor-pointer"
          @change="chanceIsValid"
        />
        <a
          class="text-white underline"
          :href="$store.state.consentStatement.url"
          target="_blank"
          >მე ვადარებთ მაღალიანი ნებართვის გამოცდილება.</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import micTypeModal from "./micTypeModal.vue";
export default {
  name: "start-btn",
  components: {
    micTypeModal,
  },
  data() {
    return {
      isValid: this.$store.state.consentStatement.isValid,
      selectedMicType: null,
    };
  },
  methods: {
    handleMicTypeResult(type) {
      this.selectedMicType = type;
    },
    async start() {
      if (this.$store.state.consentStatement.isValid) {
        
        if (this.selectedMicType === null) {
          return;
        }

        this.$store.commit("setMicActiveType", this.selectedMicType);
        try {
          let stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });

          if (stream.active) {
            this.$router.push("/interview/inform");
          } else {
            throw new Error("მიკროფონი არ არის აქტიური.");
          }
        } catch (err) {
          console.error(err)
          this.authController("გთხოვთ, დაეთანხმეთ მიკროფონის ნებართვას და დარწმუნდით, რომ თქვენი მიკროფონი აქტიურია.");
        }
      } else {
        this.authController("გთხოვთ, განათავსოთ მაღალი ნდობის გასაღები.");
      }
    },
    chanceIsValid() {
      this.isValid = !this.isValid;
      this.$store.commit("chanceIsValid", this.isValid);
    },
  },
};
</script>
